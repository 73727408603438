@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;700&display=swap');

body{
    background-color: #060a16 !important;
}

p{
    margin-bottom:0 !important;
}

a{
    text-decoration: none !important;
}

*{
    font-family: 'Urbanist', sans-serif;
    color: #ffffff;
}

.gradientText {
	text-transform: uppercase;
	background: linear-gradient(90.1deg, #F626BC 22.56%, #A9123F 69.7%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.mainHome{
    background: url('./assets/logoBackground.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 300px;
}

.imgProject{
    border-radius: 40px 40px 0 0;
    height: 300px;
    object-fit: cover;
}

.containerProject{
    border-radius: 40px ;
    background:rgba(255, 255, 255, 0.1);
}

.letraPeq{
    font-size: 0.8rem;
}

.contOpacity{
    background:rgba(255, 255, 255, 0.1);
}

.luv{
    grid-column: 1;
    grid-row: 1;
}